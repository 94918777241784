import { createSlice } from '@reduxjs/toolkit';
import { handleBlanks, handleBlanksCategories } from './blanks/helpers';
import { getBlanksCategoriesThunk } from './blanks/thunks';
import { handleReports } from './reports/helpers';
import { handleSessions, handleSessionsCategories } from './sessions/helpers';
import { getCategorySessionsThunk } from './sessions/thunks';
import { handlePolls, handlePollsCategories } from './polls/helpers';
import { getPollsCategoriesThunk } from './polls/thunks';

const state = {
	activePlace: null,
	advertisements: [],
	selectAdvertisement: null,
	categoryAdvertisements: [],
	events: [],
	selectEvent: null,
	news: [],
	selectNews: null,
	categoryNews: [],
	usersSearch: [],
	forms: { formList: null, categoryForms: [], selectForm: null, onLineSteps: [] },
	statements: {
		isLoadingStatements: false,
		statementsList: null,
		statementsColumns: null,
		archivedStatementsList: null,
	},
	services: {
		servicesList: [],
		servicesCategories: [],
		selectedServiceCat: null,
		selectedService: null,
	},
	staff: {
		staffList: [],
		isModalAddStaffOpen: false,
		allUsers: null,
		currentUser: null,
		staffRequest: [],
	},
	blanks: {
		blanksList: null,
		categoryBlanks: null,
	},
	reports: {
		reportsList: null,
	},
	sessions: {
		sessionsList: null,
		categorySessions: null,
	},
	permissions: [],
	polls: {
		pollsList: null,
		categoryPolls: null,
	},
	calendarModalData: {
		isModalAddEventOpen: false,
		currentDate: null,
	},
	departments: { departmentsList: [], isModalDeleteOpen: false, selectedDepartment: null },
	specialDays: null,
	cityCouncilAdmins: null,
	notification: {
		readNotificationsData: null,
		staffRequests: null,
		events: null,
		statements: null,
		appointments: null,
	},
};

export const cityCouncilSlice = createSlice({
	name: 'cityCouncil',
	initialState: state,
	reducers: {
		addActivePlace: (prevState, { payload }) => ({
			...state,
			activePlace: payload,
			notification: { ...prevState.notification },
		}),
		addAdvertisements: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			advertisements: payload,
		}),
		addSelectAdvertisement: (prevState, { payload }) => ({
			...prevState,
			selectAdvertisement: payload,
		}),
		addCategoryAdvertisements: (prevState, { payload }) => ({
			...prevState,
			categoryAdvertisements: payload,
		}),
		addEvents: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			events: payload,
		}),
		addSelectEvent: (prevState, { payload }) => ({
			...prevState,
			selectEvent: payload,
		}),
		addNews: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			news: payload,
		}),
		addSelectNews: (prevState, { payload }) => ({
			...prevState,
			selectNews: payload,
		}),
		addCategoryNews: (prevState, { payload }) => ({
			...prevState,
			categoryNews: payload,
		}),
		updUsersSearch: (prevState, { payload }) => ({
			...prevState,
			usersSearch: payload,
		}),
		addForms: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			forms: {
				...prevState.forms,
				formList: payload,
				selectForm: null,
				onLineSteps: [],
			},
			services: {
				...prevState.services,
			},
		}),
		addCategoryForms: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			forms: {
				...prevState.forms,
				categoryForms: payload,
				selectForm: null,
				onLineSteps: [],
			},
		}),
		addSelectForm: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			forms: {
				...prevState.forms,
				selectForm: payload,
				onLineSteps: payload.steps || [],
			},
		}),
		updateOnLineSteps: (prevState, { payload }) => ({
			...prevState,
			forms: {
				...prevState.forms,
				onLineSteps: payload,
			},
		}),

		addStatementsColumns: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			statements: {
				...prevState.statements,
				statementsColumns: payload,
			},
		}),
		changeIsLoadingStatements: (prevState, { payload }) => ({
			...prevState,
			statements: {
				...prevState.statements,
				isLoadingStatements: payload,
			},
		}),
		addStatements: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			statements: {
				...prevState.statements,
				statementsList: payload,
			},
		}),
		updateStatementsColumns: (prevState, { payload }) => ({
			...prevState,
			statements: {
				...prevState.statements,
				statementsColumns: payload,
			},
		}),
		updateStatements: (prevState, { payload }) => ({
			...prevState,
			statements: {
				...prevState.statements,
				statementsList: payload,
			},
		}),
		updateArchivedStatementsList: (prevState, { payload }) => ({
			...prevState,
			statements: {
				...prevState.statements,
				archivedStatementsList: payload,
			},
		}),

		addServicesCategories: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			services: {
				// ...prevState.services,
				servicesCategories: payload,
			},
		}),
		addServices: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			services: {
				...prevState.services,
				servicesList: payload,
			},
		}),
		updSelectServiceCat: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			services: {
				...prevState.services,
				selectedServiceCat: payload,
			},
		}),
		updSelectService: (prevState, { payload }) => ({
			...prevState,
			activePlace: prevState.activePlace,
			services: {
				...prevState.services,
				selectedService: payload,
			},
		}),
		addStaff: (prevState, { payload }) => ({
			...prevState,
			staff: {
				...prevState.staff,
				staffList: payload,
				currentUser: null,
			},
		}),
		updModalAddStaffOpen: (prevState, { payload }) => ({
			...prevState,
			staff: {
				...prevState.staff,
				isModalAddStaffOpen: payload,
			},
		}),
		updatePermissions: (prevState, { payload }) => ({
			...prevState,
			permissions: payload,
		}),
		getAllUsersList: (prevState, { payload }) => ({
			...prevState,
			staff: {
				...prevState.staff,
				allUsers: payload,
			},
		}),
		addCurrentUser: (prevState, { payload }) => ({
			...prevState,
			staff: {
				...prevState.staff,
				currentUser: payload,
			},
		}),
		updCalendarModalData: (prevState, { payload }) => ({
			...prevState,
			calendarModalData: {
				isModalAddEventOpen: payload,
			},
		}),
		addStaffRequest: (prevState, { payload }) => ({
			...prevState,
			staff: {
				...prevState.staff,
				staffRequest: payload,
			},
		}),
		addDepartments: (prevState, { payload }) => ({
			...prevState,
			departments: {
				...prevState.departments,
				departmentsList: payload,
			},
		}),
		updModalDeleteDepartment: (prevState, { payload }) => ({
			...prevState,
			departments: {
				...prevState.departments,
				isModalDeleteOpen: payload.isOpen,
				selectedDepartment: payload.selectedDepartment,
			},
		}),
		addSpecialDays: (prevState, { payload }) => ({
			...prevState,
			specialDays: payload,
		}),
		addCityCouncilAdmins: (prevState, { payload }) => ({
			...prevState,
			cityCouncilAdmins: payload,
		}),
		addNotifications: (prevState, { payload }) => ({
			...prevState,
			notification: {
				...prevState.notification,
				...payload,
			},
		}),

		setBlanksOperation: handleBlanks,
		setReportsOperation: handleReports,
		setSessionsOperation: handleSessions,
		setPollsOperation: handlePolls,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBlanksCategoriesThunk.fulfilled, handleBlanksCategories)
			.addCase(getCategorySessionsThunk.fulfilled, handleSessionsCategories)
			.addCase(getPollsCategoriesThunk.fulfilled, handlePollsCategories);
	},
});
export const { setBlanksOperation, setReportsOperation, setSessionsOperation, setPollsOperation } =
	cityCouncilSlice.actions;
