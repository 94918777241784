import { createSlice } from '@reduxjs/toolkit';

const MyBalanceSlice = createSlice({
	name: 'business/myBalance',
	initialState: {
		invoices: null,
		reports: null,
		tariffHistory: null,
		tax: null,
		transactionHistory: null,
	},
	reducers: {},
	extraReducers: () => {},
});

export const MyBalanceReducer = MyBalanceSlice.reducer;
// export const {} = MyBalanceSlice.actions;
