import { createSlice } from '@reduxjs/toolkit';
import { handleAllOrders } from './helpers';

const OrdersSlice = createSlice({
	name: 'business/orders',
	initialState: {
		allOrders: null,
	},
	reducers: {
		setAllOrders: handleAllOrders,
	},
	extraReducers: () => {},
});

export const OrdersReducer = OrdersSlice.reducer;
export const { setAllOrders } = OrdersSlice.actions;
