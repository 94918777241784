import { createSlice } from '@reduxjs/toolkit';
import {
	handleCRMStatuses,
	handleOrders,
	handleActiveOrder,
	handleMoreOnlineResponsiblePersons,
	handleAboutLeadOnlineDynamicOwnFields,
} from './helpers';

const CRMSlice = createSlice({
	name: 'business/crm',
	initialState: {
		CRMStatuses: [],
		orders: null,
		activeOrder: null,
		moreOnlineResponsiblePersons: null,
		aboutLeadOnlineDynamicOwnFields: null,
	},
	reducers: {
		setCRMStatuses: handleCRMStatuses,
		setOrders: handleOrders,
		setActiveOrder: handleActiveOrder,
		setMoreOnlineResponsiblePersons: handleMoreOnlineResponsiblePersons,
		setAboutLeadOnlineDynamicOwnFields: handleAboutLeadOnlineDynamicOwnFields,
	},
	extraReducers: () => {},
});

export const CRMReducer = CRMSlice.reducer;

export const {
	setCRMStatuses,
	setOrders,
	setActiveOrder,
	setMoreOnlineResponsiblePersons,
	setAboutLeadOnlineDynamicOwnFields,
} = CRMSlice.actions;
