import {
	handleClearAppointments,
	handleAppointments,
	handleArchivedAppointments,
} from './helpers';

const { createSlice } = require('@reduxjs/toolkit');

const appointmentsSlice = createSlice({
	name: 'appointments',
	initialState: {
		appointments: null,
		archivedAppointments: null,
	},
	reducers: {
		setAppointments: handleAppointments,
		setArchivedAppointments: handleArchivedAppointments,
		clearAppointments: handleClearAppointments,
	},
});

export const { setAppointments, clearAppointments, setArchivedAppointments } = appointmentsSlice.actions;
export const appointmentsReducer = appointmentsSlice.reducer;
