export const handleAppointments = (state, { payload }) => {
	state.appointments = payload;
};
export const handleArchivedAppointments = (state, { payload }) => {
	state.archivedAppointments = payload;
};
export const handleClearAppointments = (state) => {
	state.appointments = null;
	state.archivedAppointments = null;
};
