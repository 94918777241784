import { createSlice } from '@reduxjs/toolkit';

const GoodsSlice = createSlice({
	name: 'business/goods',
	initialState: {
		activeGoods:null,
		forConfirmation:null,
		moderation:null,
		newGoods:null,
		notActiveGoods:null,
	},
	reducers: {},
	extraReducers: () => {},
});

export const GoodsReducer = GoodsSlice.reducer;
// export const {} = GoodsSlice.actions;
