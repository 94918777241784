import {
	handleClearProblems,
	handleProblems,
	handleProblemsColumns,
	handleTownes,
	handleArchivedProblems,
	handleNewProblemsCounter,
} from './helpers';
import { getProblemsColumnsThunk, getTownesByCommunityThunk } from './thunks';

const { createSlice } = require('@reduxjs/toolkit');

const problemsSlice = createSlice({
	name: 'problems',
	initialState: {
		problems: null,
		archivedProblems: null,
		columns: null,
		townes: null,
		newProblemsCounter: 0,
	},
	reducers: {
		setProblems: handleProblems,
		setArchivedProblems: handleArchivedProblems,
		clearProblems: handleClearProblems,
		setNewProblemsCounter: handleNewProblemsCounter,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getProblemsColumnsThunk.fulfilled, handleProblemsColumns)
			.addCase(getTownesByCommunityThunk.fulfilled, handleTownes);
	},
});

export const { setProblems, clearProblems, setArchivedProblems, setNewProblemsCounter } =
	problemsSlice.actions;
export const problemsReducer = problemsSlice.reducer;
