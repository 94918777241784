export const handleDealsStatuses = (state, { payload }) => {
	state.dealsStatuses = payload;
};

export const handleDeals = (state, { payload }) => {
	state.deals = payload;
};

export const handleActiveDeal = (state, { payload }) => {
	state.activeDeal = payload;
};
