import { createSlice } from '@reduxjs/toolkit';
import {
	handleActiveIntegrations,
	
} from './helpers';

const integrationsSlice = createSlice({
	name: 'business/integrations',
	initialState: {
		activeIntegrations: [],
		
	},
	reducers: {
		setActiveIntegrations: handleActiveIntegrations,
		
	},
	extraReducers: () => {},
});

export const integrationsReducer = integrationsSlice.reducer;

export const { setActiveIntegrations } = integrationsSlice.actions;
