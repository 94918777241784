export const handleCRMStatuses = (state, { payload }) => {
	state.CRMStatuses = payload;
};

export const handleOrders = (state, { payload }) => {
	state.orders = payload;
};

export const handleActiveOrder = (state, { payload }) => {
	state.activeOrder = payload;
};

export const handleMoreOnlineResponsiblePersons = (state, { payload }) => {
	state.moreOnlineResponsiblePersons = payload;
};

export const handleAboutLeadOnlineDynamicOwnFields = (state, { payload }) => {
	state.aboutLeadOnlineDynamicOwnFields = payload;
};
