import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import GuestGuard from 'guards/GuestGuard';
import { Loadable } from './Loadable';

export default function GuestRouter() {
	return (
		<GuestGuard>
			<Routes>
				<Route path="/login" element={<LoginPage />} />
				<Route path="/registration" element={<RegAdmin />} />
			</Routes>
		</GuestGuard>
	);
}

const LoginPage = Loadable(lazy(() => import('../pages/LoginPage/LoginPage')));
const RegAdmin = Loadable(lazy(() => import('../pages/RegAdmin/RegAdmin')));
