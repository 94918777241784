import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { authSlice } from './auth/authReducer';
import { communitySlice } from './community/communityReducer';
import { cityCouncilSlice } from './city-council/cityCouncilReducer';
import { rootReducer } from './root/rootSlice';
import { problemsReducer } from './problems/slice';
import { notificationsReducer } from './notifications/slice';
import { appointmentsReducer } from './appointments/slice';
import { businessRootReducer } from './business/businessReducer';

const reducer = {
	[authSlice.name]: authSlice.reducer,
	[communitySlice.name]: communitySlice.reducer,
	[cityCouncilSlice.name]: cityCouncilSlice.reducer,
	root: rootReducer,
	problems: problemsReducer,
	notifications: notificationsReducer,
	appointments: appointmentsReducer,
	business: businessRootReducer,
};

const middleware = getDefaultMiddleware({
	serializableCheck: false,
});

export const store = configureStore({
	reducer,
	middleware,
});
