import { doc, getDoc, getFirestore } from 'firebase/firestore';
import '../../firebase';

const db = getFirestore();

export const getSingleDoc = async (id, collection) => {
	const elementRef = doc(db, collection, id);

	try {
		const elementDoc = await getDoc(elementRef);

		if (!elementDoc.exists()) {
			return Promise.reject('element not found');
		}
		return elementDoc.data();
	} catch (error) {
		return error;
	}
};
