import { useDispatch, useSelector } from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import { loadingSelector } from 'redux/root/selectors';
import { FormWrapper } from './utils/Questions.styled'
import LoaderAbsolute from 'components/LoaderAbsolute/LoaderAbsolute';
import { message } from 'utils/GlobalComponent';
import { scrollToElement } from 'constants/scrollToElement';
import QuestionsForm from "./components/QuestionsForm";
import QuestionsLayout from "./QuestionsLayout";
import {addVotingQuestionsThunk} from "../../../../redux/community/voting-questions/thunks";

const QuestionsCreate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {sessionId} = useParams();
    const isLoading = useSelector(loadingSelector);

    const onFinish = (formData) => {
        dispatch(addVotingQuestionsThunk({ data: {...formData, date: formData?.date?.$d}, sessionId }))
            .unwrap()
            .then(() => navigate(-1));
    };

    const onFinishFailed = (error) => {
        error.errorFields.forEach((element) => {
            if (element.name[0] === 'name') {
                message.error('Заповніть Назву', 5);
                scrollToElement('name');
            }
        });
    };

    return (
        <QuestionsLayout>
            <FormWrapper className="form">
                {isLoading && <LoaderAbsolute />}
                <QuestionsForm onFinish={onFinish} onFinishFailed={onFinishFailed} mode="create" />
            </FormWrapper>
        </QuestionsLayout>
    );
};

export default QuestionsCreate;
