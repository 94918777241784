import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getFirestore,
    collection,
    query,
    where,
    onSnapshot,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    Timestamp
} from 'firebase/firestore';
import '../../../firebase';
import { setPollsOperation } from '../cityCouncilReducer';
import {message} from "../../../utils/GlobalComponent";

const db = getFirestore();

export const getPollsCategoriesThunk = createAsyncThunk(
    'polls/getCategories',
    async (_, { rejectWithValue }) => {
        try {
            const q = query(collection(db, 'polls_categories'));
            return (await getDocs(q)).docs.map((doc) => doc.data());
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const getPollsThunk = createAsyncThunk(
    'polls/getPolls',
    async (_, { dispatch }) => {
        const q = query(
            collection(db, 'polls'),
            where('deleted', '==', false)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const result = [];
            querySnapshot.forEach((doc) => {
                result.push({
                    ...doc.data(),
                    key: doc.id,
                });
            });
            dispatch(setPollsOperation(result));
        });

        return unsubscribe;
    }
);

export const addPollThunk = createAsyncThunk(
    'polls/addPoll',
    async ({ data, collectionQuery }, { rejectWithValue }) => {

        try {
            const docRef = await addDoc(collection(db, collectionQuery), data);

            if (docRef) {
                    await updateDoc(doc(db, collectionQuery , docRef.id), {
                        id: docRef.id,
                        createdAt: Timestamp.fromDate(new Date()),
                        deleted: false,
                    });

                message.success('Документ успішно створено');
            }
        } catch (error) {
            message.error('Під час створення виникла помилка');
            rejectWithValue(error.message ?? 'Created with error');
        }
    }
);

export const editPollThunk = createAsyncThunk(
    'cityCouncil/polls/editPoll',
    async ({ data, collectionQuery }, { rejectWithValue }) => {
        try {
                await updateDoc(doc(db, collectionQuery, data.id), {
                    ...data,
                    updatedAt: Timestamp.fromDate(new Date()),
                });

                message.success('Документ успішно створено');
        } catch (error) {
            message.error('Під час створення виникла помилка');
            rejectWithValue(error.message ?? 'Created with error');
        }
    }
)

