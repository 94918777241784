import { handleClearNotifications, handleNotifications } from './helpers';

const { createSlice } = require('@reduxjs/toolkit');

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: {
		notifications: null,
	},
	reducers: {
		setNotifications: handleNotifications,
		clearNotifications: handleClearNotifications,
	},
});

export const { setNotifications, setArchivedNotifications, clearNotifications } =
	notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
