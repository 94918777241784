import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loadable } from './Loadable';
import QuestionsEdit from "../components/CommunityComponents/Voting/Questions/QuestionsEdit";
import QuestionsCreate from "../components/CommunityComponents/Voting/Questions/QuestionsCreate";

export default function CommunityRouter() {
	return (
		<Routes>
			<Route path="/" element={<CommunityPage />}>
				<Route path="home" element={<HomeCommunity />} />
				<Route path="places" element={<PlacesCommunity />} />
				<Route path="places/create" element={<PlacesEditCreateCommunity />} />
				<Route path="places/edit/:id" element={<PlacesEditCreateCommunity />} />
				<Route path="problems" element={<Navigate to="urgent" />} />
				<Route path="problems/urgent" element={<Problems />} />
				<Route path="problems/archived" element={<ProblemsArchived />} />
				<Route path="advertisements" element={<AdvertisementsCommunity />} />
				<Route path="events" element={<EventsCommunity />} />
				<Route path="sessions" element={<Sessions />} />
				<Route path="sessions/:sessionId/questions" element={<Questions />} />
				<Route path="sessions/:sessionId/questions/edit/:id" element={<QuestionsEdit />} />
				<Route path="sessions/:sessionId/questions/create" element={<QuestionsCreate />} />
				<Route path="sessions/create" element={<CreateSessions />} />
				<Route path="sessions/edit/:id" element={<EditSessions />} />
				<Route path="news" element={<NewsCommunity />} />
				<Route path="services" element={<ServicesCommunity />} />
				<Route path="categories" element={<CategoriesCommunity />} />
				<Route path="categories/addcategory" element={<AddCategoryCommunity />} />
				<Route path="categories/editcategory" element={<EditCategoryCommunity />} />
				<Route path="categories/establishmentlist" element={<EstablishmentList />} />
				<Route path="categories/addestablishment" element={<AddEstablishment />} />
				<Route path="categories/:categoryId/chat" element={<CategoryChats />} />
				<Route path="categories/:categoryId/chat/create" element={<CreateCategoryChats />} />
				<Route path="categories/:categoryId/chat/edit/:id" element={<EditCategoryChats />} />
				<Route path="categories/:categoryId/activities" element={<CategoryActivities />} />
				<Route path="categories/:categoryId/activities/create" element={<CreateCategoryActivities />} />
				<Route path="categories/:categoryId/activities/edit/:id" element={<EditCategoryActivities />} />
				<Route path="categories/:categoryId/place" element={<CategoryCulture />} />
				<Route path="categories/:categoryId/place/create" element={<CreateCategoryCulture />} />
				<Route path="categories/:categoryId/place/edit/:id" element={<EditCategoryCulture />} />
				<Route path="categories/:categoryId/transport" element={<CategoryTransport />} />
				<Route path="categories/:categoryId/transport/create" element={<CreateCategoryTransport />} />
				<Route path="categories/:categoryId/transport/edit/:id" element={<EditCategoryTransport />} />
				<Route path="categories/:categoryId/faq" element={<CategoryFaq />} />
				<Route path="categories/:categoryId/faq/create" element={<CreateCategoryFaq />} />
				<Route path="categories/:categoryId/faq/edit/:id" element={<EditCategoryFaq />} />
				<Route path="categories/:categoryId/faq/:id/questions" element={<CategoryFaqQuestions />} />
				<Route path="categories/:categoryId/faq/:id/questions/create" element={<CreateCategoryFaqQuestions />} />
				<Route path="categories/:categoryId/faq/:id/questions/edit/:index" element={<EditCategoryFaqQuestions />} />
				<Route path="categories/:categoryId/establishment" element={<CategoryEstablishment />} />
				<Route path="sos" element={<SosCommunity />} />
				<Route path="sos/create" element={<SosCreateCommunity />} />
				<Route path="sos/edit/:id" element={<SosEditCommunity />} />
				<Route path="users" element={<UsersCommunity />} />
				<Route path="chats/*" element={<Chats />}>
					<Route path="chats/:id" element={<Chats />} />
				</Route>
				<Route path="notifications" element={<PushNotifications />} />
				<Route path="notifications/create" element={<CreatePushNotifications />} />
				<Route path="polls" element={<Polls />} />
				<Route path="polls/edit/:id" element={<EditPolls />} />
				<Route path="polls/create" element={<CreatePolls />} />
				<Route path="polls/statistics/:id" element={<StatisticsPolls />} />
				<Route path="about-community" element={<AboutCommunity />} />
				<Route path="towns" element={<PopulatedAreasList />} />
				<Route path="towns/:id" element={<PopulatedArea />} />
				<Route path="admins" element={<AdminsCommunity />} />
				<Route path="help" element={<HowToUseList />} />
				<Route path="help/:id/:suggestionId" element={<HowToUseItem />} />
			</Route>
		</Routes>
	);
}

const CommunityPage = Loadable(lazy(() => import('pages/CommunityPage/CommunityPage')));
const HomeCommunity = Loadable(lazy(() => import('components/CommunityComponents/HomeCommunity')));
const PlacesCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/Places/PlacesCommunity'))
);

const PlacesEditCreateCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/Places/PlacesEditCreateCommunity'))
);

const Problems = Loadable(lazy(() => import('components/Problems')));
const ProblemsArchived = Loadable(lazy(() => import('components/Problems/ProblemsArchived')));
const AdvertisementsCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/AdvertisementsCommunity'))
);
const EventsCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/EventsCommunity'))
);
const NewsCommunity = Loadable(lazy(() => import('components/CommunityComponents/NewsCommunity')));
const ServicesCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/ServicesCommunity'))
);
const UsersCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/UsersCommunity/UsersCommunity'))
);
const PushNotifications = Loadable(lazy(() => import('components/PushNotifications')));
const CreatePushNotifications = Loadable(
	lazy(() => import('components/PushNotifications/CreatePushNotifications'))
);
const Polls = Loadable(lazy(() => import('components/Polls/Polls')));
const EditPolls = Loadable(lazy(() => import('components/Polls/EditPolls')));
const CreatePolls = Loadable(lazy(() => import('components/Polls/CreatePolls')));
const StatisticsPolls = Loadable(lazy(() => import('components/Polls/StatisticsPolls')));
const AboutCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/AboutCommunity/AboutCommunity'))
);
const AdminsCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/AdminsCommunity'))
);

const CategoriesCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/CategoriesCommunity'))
);
const AddCategoryCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/AddCategoryCommunity'))
);
const EditCategoryCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/EditCategoryCommunity'))
);
const EstablishmentList = Loadable(
	lazy(() => import('components/EstablishmentList/EstablishmentList'))
);
const AddEstablishment = Loadable(
	lazy(() => import('components/_AddEstablishment/AddEstablishment'))
);
const SosCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/Sos/SosCommunity'))
);
const SosCreateCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/Sos/SosCreateCommunity'))
);

const SosEditCommunity = Loadable(
	lazy(() => import('components/CommunityComponents/Sos/SosEditCommunity'))
);
const Chats = Loadable(lazy(() => import('components/Chats/Chats')));

const CategoryChats = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Chats/Chats'))
);

const CreateCategoryChats = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Chats/CreateChats'))
);

const EditCategoryChats = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Chats/EditChats'))
);

const CategoryFaq = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Faq/Faq'))
);

const CreateCategoryFaq = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Faq/CreateFaq'))
);

const EditCategoryFaq = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Faq/EditFaq'))
);

const CategoryFaqQuestions = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Faq/Questions/Questions'))
);

const CreateCategoryFaqQuestions = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Faq/Questions/CreateQuestions'))
);

const EditCategoryFaqQuestions = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Faq/Questions/EditQuestions'))
);

const CategoryEstablishment = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Establishment/Establishment'))
);

const PopulatedAreasList = Loadable(
	lazy(() => import('components/CommunityComponents/PopulatedAreas/PopulatedAreasList'))
);

const PopulatedArea = Loadable(
	lazy(() => import('components/CommunityComponents/PopulatedAreas/PopulatedArea'))
);

const HowToUseList = Loadable(
	lazy(() => import('components/HowToUse/HowToUseList'))
);
const HowToUseItem = Loadable(
	lazy(() => import('components/HowToUse/HowToUseItem'))
);

const CategoryTransport = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Transport/Transport'))
);

const CreateCategoryTransport = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Transport/CreateTransport'))
);

const EditCategoryTransport = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Transport/EditTransport'))
);

const CategoryCulture = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Culture/Culture'))
);

const CreateCategoryCulture = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Culture/CreateCulture'))
);

const EditCategoryCulture = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Culture/EditCulture'))
);

const CategoryActivities = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Activities/Activities'))
);

const CreateCategoryActivities = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Activities/CreateActivities'))
);

const EditCategoryActivities = Loadable(
	lazy(() => import('components/CommunityComponents/Categories/Activities/EditActivities'))
);

const Sessions = Loadable(
	lazy(() => import('components/CommunityComponents/Voting/Sessions/Sessions'))
);

const CreateSessions = Loadable(
	lazy(() => import('components/CommunityComponents/Voting/Sessions/SessionsCreate'))
);

const EditSessions = Loadable(
	lazy(() => import('components/CommunityComponents/Voting/Sessions/SessionsEdit'))
);

const Questions = Loadable(
	lazy(() => import('components/CommunityComponents/Voting/Questions/Questions'))
);

const CreateQuestions = Loadable(
	lazy(() => import('components/CommunityComponents/Voting/Questions/QuestionsCreate'))
);

const EditQuestions = Loadable(
	lazy(() => import('components/CommunityComponents/Voting/Questions/QuestionsEdit'))
);

