import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getFirestore,
	collection,
	query,
	where,
	onSnapshot,
	getDocs,
	orderBy,
	updateDoc,
	doc,
	writeBatch,
	Timestamp,
} from 'firebase/firestore';
import '../../firebase';
import { setProblems, setArchivedProblems, setNewProblemsCounter } from './slice';
import { message } from 'utils/GlobalComponent';

const db = getFirestore();

export const getProblemsColumnsThunk = createAsyncThunk(
	'problems/getProblemsColumns',
	async (_, { rejectWithValue }) => {
		try {
			const q = query(collection(db, 'problems_columns'));
			return (await getDocs(q)).docs.map((doc) => doc.data());
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getProblemsThunk = createAsyncThunk(
	'problems/getProblems',
	async ({ communityId, archived = false }, { dispatch }) => {
		const q = query(
			collection(db, 'problems'),
			where('communityId', '==', communityId),
			where('archived', '==', archived),
			orderBy('updatedDate', 'desc')
		);

		const unsubscribe = onSnapshot(q, async (querySnapshot) => {
			const result = [];
			querySnapshot.forEach((doc) => {
				result.push({
					...doc.data(),
					id: doc.id,
				});
			});

			const resultWithUser = [];
			for (let v = 0; v < result.length; v++) {
				if (!result[v].anonymously && result[v].createBy) {
					const q2 = query(collection(db, 'users'), where('id', '==', result[v].createBy));
					const querySnapshot2 = await getDocs(q2);
					const user = querySnapshot2.docs.map((doc) => doc.data());
					const {
						firstName = '',
						lastName = '',
						thirdName = '',
						avatar = null,
						email,
						phone,
					} = user[0];

					if (user.length > 0) {
						resultWithUser.push({
							...result[v],
							userFullName:
								!firstName && !lastName && !thirdName
									? '-'
									: firstName + ' ' + lastName + ' ' + thirdName,
							userAvatar: avatar,
							userEmail: email,
							userPhone: phone,
							userInfo: user[0],
						});
					} else {
						resultWithUser.push({
							...result[v],
						});
					}
				} else {
					resultWithUser.push({
						...result[v],
						userFullName: 'Anonymously',
						userAvatar: '',
						userEmail: 'no email',
						userPhone: 'no phone',
					});
				}
			}

			archived
				? dispatch(setArchivedProblems(resultWithUser))
				: dispatch(setProblems(resultWithUser));
		});

		return unsubscribe;
	}
);

export const getNewProblemsCounterThunk = createAsyncThunk(
	'problems/getNewProblemsCounter',
	async ({ communityId }, { dispatch }) => {
		const q = query(
			collection(db, 'problems'),
			where('communityId', '==', communityId),
			where('archived', '==', false),
			where('status', '==', 'New')
		);

		const unsubscribe = onSnapshot(q, async (querySnapshot) => {
			const result = [];
			querySnapshot.forEach((doc) => {
				result.push({
					...doc.data(),
					id: doc.id,
				});
			});
			dispatch(setNewProblemsCounter(result.length));
		});

		return unsubscribe;
	}
);

export const archivedProblemThunk = createAsyncThunk(
	'problems/archived',
	async (id, { rejectWithValue }) => {
		try {
			await updateDoc(doc(db, 'problems', id), {
				archived: true,
			});
			message.success('Документ успішно перенесено в архів');
		} catch (error) {
			message.error('Виникла помилка під час архівації');
			return rejectWithValue(error.message ?? 'error archivedProblem');
		}
	}
);

export const updateProblemsThunk = createAsyncThunk(
	'problems/update',
	async ({ id, status }, { rejectWithValue }) => {
		try {
			const batch = writeBatch(db);

			batch.update(doc(db, 'problems', id), {
				status,
				updatedDate: Timestamp.fromDate(new Date()),
			});

			await batch.commit();
			return;
		} catch (error) {
			console.log('error', error);
			return rejectWithValue(error.message ?? 'error update problems');
		}
	}
);

export const getTownesByCommunityThunk = createAsyncThunk(
	'problems/getTownesByCommunity',
	async (_, { rejectWithValue, getState }) => {
		const communityId = getState().community.activeCommunityId;
		try {
			const q = query(collection(db, 'townes'), where('parentId', '==', communityId));
			return (await getDocs(q)).docs.map((doc) => doc.data());
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);
