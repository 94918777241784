import { createSlice } from '@reduxjs/toolkit';

const ReviewsSlice = createSlice({
	name: 'business/reviews',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const ReviewsReducer = ReviewsSlice.reducer;
// export const {} = ReviewsSlice.actions;
