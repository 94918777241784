import { createSlice } from '@reduxjs/toolkit';

const DocumentFlowSlice = createSlice({
	name: 'business/documentFlow',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const DocumentFlowReducer = DocumentFlowSlice.reducer;
// export const {} = DocumentFlowSlice.actions;
