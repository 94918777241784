export const handleGallery = (state, { payload }) => {
	state.gallery = payload;
};

export const handleCommunity = (state, { payload }) => {
	state.currentCommunity = payload.community;
	state.communityId = payload.id;
}

export const handleCurrentGallery = (state, {payload}) => {
	state.currentGallery = payload;
}
