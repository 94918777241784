export const localTextErrorAuth = (code) => {
  if (code === "auth/user-not-found") return "Користувач не знайдений";
  if (code === "auth/invalid-email") return "Невірно введений логін (email)";
  if (code === "auth/wrong-password") return "Некоректний пароль";
  if (code === "auth/missing-password") return "Відсутній пароль";

  if (code === "auth/weak-password") return "Пароль занадтно слабкий";
  if (code === "auth/missing-password") return "Відсутній пароль";
  if (code === "auth/missing-password") return "Відсутній пароль";
  if (code === "auth/missing-password") return "Відсутній пароль";

  return "Виникла помилка";
  //   return code;
};
