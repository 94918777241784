import axios from 'axios';

export const createMeeting = async ({ title, datetime, participants, ownerId }) => {
	try {
		const { data } = await axios.post('createMeeting', { title, datetime, participants, ownerId });
		return data;
	} catch (error) {
		console.log('error :>> ', error);
	}
};

export const getToken = async ({ ownerId }) => {
	try {
		const { data } = await axios.post('getToken', {
			ownerId,
			secretKey: process.env.REACT_APP_STREAM_IO_SECRET_API_KEY,
		});
		return data;
	} catch (error) {
		console.log('error :>> ', error);
	}
};

export const getActiveCall = async ({ userId, meetId }) => {
	try {
		const { data } = await axios.post('getActiveCall', {
			userId,
			meetId,
		});
		return data;
	} catch (error) {
		console.log('error :>> ', error);
	}
};
