import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import GuestRouter from './GuestRoutes';
import AuthRouter from './AuthRoutes';
import { Loadable } from './Loadable';

export default function Router() {
	return (
		<Routes>
			<Route path="/auth/*" element={<GuestRouter />} />
			<Route path="/*" element={<AuthRouter />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}
const NotFoundPage = Loadable(lazy(() => import('pages/NotFoundPage/NotFoundPage')));
