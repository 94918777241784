import { App } from 'antd';
import { getSingleDoc } from 'api/cityCouncil/docs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const useGetSingleDoc = (collection) => {
	const { message } = App.useApp();
	const { id } = useParams();

	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');

	const getDoc = async () => {
		try {
			setIsLoading(true);
			const data = await getSingleDoc(id, collection);
			setData(data);
		} catch (error) {
			setError(error.message ?? 'some error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getDoc();
	}, []);

	useEffect(() => {
		error && message.error(error);
	}, [error]);

	return { data, isLoading };
};

export default useGetSingleDoc;
