export const AllCRMSelector = (state) => state.business.main.activeBusiness?.crms;

export const activeCRMStatusesSelector = (state) => state.business.crm.CRMStatuses;

export const ordersSelector = (state) => state.business.crm.orders;

export const activeOrderSelector = (state) => state.business.crm.activeOrder;

export const moreOnlineResponsiblePersonsSelector = (state) =>
	state.business.crm.moreOnlineResponsiblePersons;

export const aboutLeadOnlineDynamicOwnFieldsSelector = (state) =>
	state.business.crm.aboutLeadOnlineDynamicOwnFields;
