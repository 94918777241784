import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getFirestore,
	collection,
	query,
	where,
	onSnapshot,
	getDocs,
	orderBy,
} from 'firebase/firestore';
import { setSessionsOperation } from '../cityCouncilReducer';
import '../../../firebase';

const db = getFirestore();

export const getCategorySessionsThunk = createAsyncThunk(
	'cityCancel/blanks/getSessionsCategories',
	async (_, { rejectWithValue }) => {
		try {
			const q = query(collection(db, 'sessions_categories'));
			return (await getDocs(q)).docs.map((doc) => doc.data());
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getSessionsThunk = createAsyncThunk(
	'cityCancel/blanks/getSessions',
	async (_, { getState, dispatch }) => {
		const communityId = getState().cityCouncil.activePlace.communityId;
		const q = query(
			collection(db, 'sessions'),
			where('communityId', '==', communityId),
			where('deleted', '==', false),
			orderBy('index', 'asc')
		);

		const unsubscribe = onSnapshot(q, async (querySnapshot) => {
			const result = [];
			querySnapshot.forEach((doc) => {
				result.push({
					...doc.data(),
					key: doc.id,
				});
			});

			const resultWithUserName = [];
			for (let v = 0; v < result.length; v++) {
				const q2 = query(collection(db, 'users'), where('uid', '==', result[v].userUid));
				const querySnapshot2 = await getDocs(q2);
				const user = querySnapshot2.docs.map((doc) => doc.data());
				const { firstName = '', lastName = '', thirdName = '', avatar = null, email } = user[0];

				if (user.length > 0) {
					resultWithUserName.push({
						...result[v],
						userFullName:
							!firstName && !lastName && !thirdName
								? email
								: firstName + ' ' + lastName + ' ' + thirdName,
						userAvatar: avatar,
					});
				} else {
					resultWithUserName.push({
						...result[v],
					});
				}
			}

			dispatch(setSessionsOperation(resultWithUserName));
		});

		return unsubscribe;
	}
);
