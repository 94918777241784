import { useEffect, useState } from 'react';
import {
	Chat,
	Channel,
	ChannelHeader,
	MessageList,
	MessageInput,
	Thread,
	Window,
} from 'stream-chat-react';
import 'stream-chat-react/dist/scss/v2/index.scss';
import './index.scss';
import { List } from 'antd';
import useGenerateUserForStream from 'hooks/useGenerateUserForStream';
import UserItem from './UserItem';

const StreamChatComponent = () => {
	const [channel, setChannel] = useState(null);
	const [users, setUsers] = useState(null);
	const [unreadResponse, setUnreadResponse] = useState(null);
	const { client, user, token, unreadCount } = useGenerateUserForStream();

	useEffect(() => {
		if (!token || !user || !client) return;
		client.queryUsers({}, {}, { limit: 100 }).then((data) => {
			const filteredUsers = data.users.filter(
				(el) => el.id !== user.id && el.name && el.name !== ''
			);
			for (let i = 0; i < filteredUsers.length; i++) {
				client
					.queryChannels({ type: 'messaging', members: { $eq: [user.id, filteredUsers[i].id] } })
					.then((e) => {
						console.log('e :>> ', e);
						setUsers((prev) =>
							prev
								? [...prev, { ...filteredUsers[i], channelId: e[0]?.cid }]
								: [{ ...filteredUsers[i], channelId: e[0]?.cid }]
						);
					});
			}
		});
	}, [client, token, user]);

	useEffect(() => {
		client?.getUnreadCount(user.id).then((res) => setUnreadResponse(res));
	}, [client, unreadCount, user]);

	if (!client) return null;

	const createNewChannel = (companion) => {
		setChannel(
			client.channel('messaging', {
				name: `${companion.name} & ${user.name}`,
				members: [user.id, companion.id],
			})
		);
	};

	return (
		<>
			{users && (
				<>
					<Chat client={client}>
						<List className="users-list">
							{users.map((user) => (
								<UserItem
									key={user.id}
									user={user}
									channel={channel}
									createNewChannel={createNewChannel}
									unreadResponse={unreadResponse}
								/>
							))}
						</List>
						<Channel channel={channel} markReadOnMount>
							<Window>
								<ChannelHeader />
								<MessageList markReadOnScrolledToBottom />
								<MessageInput />
							</Window>
							<Thread />
						</Channel>
					</Chat>
				</>
			)}
		</>
	);
};

export default StreamChatComponent;
