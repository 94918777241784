import { getToken } from 'api/stream';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/auth/selectors';
import { StreamChat } from 'stream-chat';

const apiKey = process.env.REACT_APP_STREAM_IO_API_KEY;

const useGenerateUserForStream = () => {
	const currentUser = useSelector(currentUserSelector);
	const [client, setClient] = useState(null);
	const [token, setToken] = useState(null);
	const [user, setUser] = useState(null);
	const [unreadCount, setUnreadCount] = useState(0);

	useEffect(() => {
		currentUser &&
			setUser({
				id: currentUser.uid,
				name: currentUser.providerData[0].displayName || currentUser.email,
				image: currentUser.providerData[0].photoUrl || '',
			});
	}, [currentUser]);

	useEffect(() => {
		if (currentUser) {
			getToken({ ownerId: currentUser.uid }).then(({ token }) => setToken(token));
		}
	}, [currentUser]);

	useEffect(() => {
		if (!token || !user) return;
		const newClient = new StreamChat(apiKey);

		const handleConnectionChange = ({ online = false }) => {
			if (!online) return console.log('connection lost');
			setClient(newClient);
			console.log('connection');
		};
		newClient.on('connection.changed', handleConnectionChange);

		newClient.on((event) => {
			if (event.total_unread_count !== undefined) {
				setUnreadCount(event.total_unread_count);
			}
		});
		newClient.getUnreadCount(user.id).then((res) => setUnreadCount(res.total_unread_count));
		newClient.connectUser(
			{
				id: user.id,
				name: user.name,
			},
			token
		);

		return () => {
			if (token && user) {
				newClient.off('connection.changed', handleConnectionChange);
				newClient.disconnectUser().then(() => console.log('connection closed'));
			}
		};
	}, [token, user]);

	return { client, user, unreadCount, token };
};
export default useGenerateUserForStream;
