import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({ fullScreen }) => {
	return fullScreen ? (
		<div
			style={{
				margin: '0 auto',
				height: '50vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<LoadingOutlined style={{ fontSize: '90px', color: '#1677ff' }} />
		</div>
	) : (
		<>
			<LoadingOutlined style={{ fontSize: '20px' }} />
		</>
	);
};
export default Loader;
