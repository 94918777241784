import {
	createUserWithEmailAndPassword,
	getAuth,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signOut,
} from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { storage } from '../../firebase';

import { authSlice, setLoaded } from './authReducer';
import { localTextErrorAuth } from 'constants/errorAuthLocalText';
const db = getFirestore();
const {
	updateUserProfile,
	authSignOut,
	authError,
	isLoadingChange,
	updPermissions,
	setLoggedUser,
} = authSlice.actions;

export const authSignUpUser =
	({ email, password }) =>
	async (dispatch, getSatte) => {
		dispatch(isLoadingChange(true));

		try {
			const auth = getAuth();

			await createUserWithEmailAndPassword(auth, email, password);
		} catch (error) {
			dispatch(authError({ updError: true }));
			console.log('error', error);
			console.log('error.message', error.message);
			dispatch(authError({ updError: true, errorMsg: localTextErrorAuth(error.code) }));
			return false;
		} finally {
			dispatch(isLoadingChange(false));
		}
	};

export const authSignInUser =
	({ email, password }) =>
	async (dispatch, getState) => {
		dispatch(isLoadingChange(true));
		try {
			const auth = getAuth();
			const { user } = await signInWithEmailAndPassword(auth, email, password);

			dispatch(setLoggedUser(user));
		} catch (error) {
			console.log('error', error);
			console.log('error.code', error.code);
			console.log('error.message', error.message);
			dispatch(authError({ updError: true, errorMsg: localTextErrorAuth(error.code) }));
		} finally {
			dispatch(isLoadingChange(false));
		}
	};

export const authSignOutUser = () => async (dispatch, getState) => {
	const auth = getAuth();
	await signOut(auth);
	await dispatch(authSignOut());
};

export const authStateCahngeUser = () => async (dispatch, getState) => {
	try {
		const auth = getAuth();

		await onAuthStateChanged(auth, async (user) => {
			if (user) {
				dispatch(authError({ updError: false, errorMsg: null }));

				dispatch(updateUserProfile({ stateChange: true }));

				dispatch(setLoggedUser(user));
			} else dispatch(setLoaded(true));
		});
	} catch (error) {
		console.log('error', error);
		console.log('error.code', error.code);
		console.log('error.message', error.message);
	}
};

// variant 1
export const getPermissions = () => async (dispatch, getState) => {
	dispatch(isLoadingChange(true));
	try {
		const auth = getAuth();

		// const timeStart = new Date();

		const q = query(
			collection(db, 'permission'),
			where('users', 'array-contains', auth.currentUser.uid)
		);
		const querySnapshot = await getDocs(q);

		const permissionUser = querySnapshot.docs.map((doc) => doc.data());

		const permissionsId = permissionUser
			.filter((item) => item.placeId || item.communityId)
			.map((item) => item.placeId || item.communityId);

		let permissions = [];
		let key = 0;

		for (let v = 0; v < permissionsId.length; v++) {
			key += 1;

			// find PLACES
			const q = query(collection(db, 'places'), where('placeId', '==', permissionsId[v]));
			const querySnapshot = await getDocs(q);
			const place = querySnapshot.docs.map((doc) => doc.data());
			if (place[0]) {
				permissions.push({
					...place[0],
					type: 'place',
					key,
				});
			} else {
				// find COMMUNYTIES
				const q2 = query(collection(db, 'communities'), where('id', '==', permissionsId[v]));
				const querySnapshot2 = await getDocs(q2);
				const communities = querySnapshot2.docs.map((doc) => doc.data());
				if (communities[0]) {
					permissions.push({
						...communities[0],
						name: communities[0].publicName.uk,
						type: 'community',
						key,
					});
				}
			}
		}

		const permissionsSort = permissions.sort((a, b) => a.type.localeCompare(b.type));

		dispatch(updPermissions(permissionsSort));

		// const timeStop = new Date();
		// console.log("time for upload:", timeStop - timeStart);
	} catch (error) {
		console.log('error', error);
		console.log('error.code', error.code);
		console.log('error.message', error.message);
	} finally {
		dispatch(isLoadingChange(false));
	}
};

// variant 2
// export const getPermissions = () => async (dispatch, getState) => {
//   try {
//     const { auth } = getState();
//     const email = auth.email;

//     const q = query(collection(db, "users"), where("email", "==", email));
//     const querySnapshot = await getDocs(q);
//     const isUsers = querySnapshot.docs.map((doc) => doc.data());
//     console.log("getPermissions  isUsers:", isUsers);

//     let timeStart;
//     if (isUsers.length > 0) {
//       timeStart = new Date();

//       const q = query(
//         collection(db, "permission")
//         // where("users", "array-contains", isUsers[0].id)
//       );
//       const querySnapshot = await getDocs(q);
//       const permissionUser = querySnapshot.docs.map((doc) => doc.data());

//       const permissionsId = permissionUser
//         .filter((item) => item.users.includes(isUsers[0].id))
//         .filter((item) => item.placeId || item.communityId)
//         .map((item) => item.placeId || item.communityId);
//       console.log("permissionsId:", permissionsId);

//       // шукаєм всі places
//       const q2 = query(
//         collection(db, "places")
//         //  where("placeId", "==", permissionsId[v])
//       );
//       const querySnapshot2 = await getDocs(q2);
//       const places = querySnapshot2.docs.map((doc) => doc.data());
//       console.log("places:", places);

//       // шукаєм всі communities
//       const q3 = query(
//         collection(db, "communities")
//         // where("id", "==", permissionsId[v])
//       );
//       const querySnapshot3 = await getDocs(q3);
//       const communities = querySnapshot3.docs.map((doc) => doc.data());
//       console.log("communities:", communities);

//       const allPlaceAndCommunities = [...places, ...communities];
//       console.log(
//         "getPermissions  allPlaceAndCommunities:",
//         allPlaceAndCommunities
//       );

//       let key = 0;
//       const permissions = allPlaceAndCommunities
//         .filter(
//           (item) =>
//             permissionsId.includes(item.placeId) ||
//             permissionsId.includes(item.id)
//         )
//         .map((item) => {
//           key += 1;
//           return {
//             ...item,
//             key,
//           };
//         });

//       console.log("permissions:", permissions);

//       // for (let v = 0; v < permissionsId.length; v++) {
//       //   key += 1;
//       // find PLACE

//       // if (permissionsId[v]) {
//       //   permissions.push({
//       // }

//       //     ...place[0],
//       //     type: "place",
//       //     key,
//       //   });
//       // } else {
//       // const permissionsSort = permissions.sort((a, b) =>
//       //   a.type.localeCompare(b.type)
//       // );

//       dispatch(updPermissions(permissions));
//       // find COMMUNYTIES
//     }

//     // }
//     let timeStop = new Date();
//     console.log(
//       "timeStart:",
//       timeStart,
//       "timeStop:",
//       timeStop,
//       "res:",
//       timeStop - timeStart
//     );
//   } catch (error) {
//     console.log("error", error);
//     console.log("error.code", error.code);
//     console.log("error.message", error.message);
//   }
// };
