import { createSlice } from '@reduxjs/toolkit';

const SettingsSlice = createSlice({
	name: 'business/settings',
	initialState: {},
	reducers: {},
	extraReducers: () => {},
});

export const SettingsReducer = SettingsSlice.reducer;
// export const {} = SettingsSlice.actions;
