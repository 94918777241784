import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getFirestore,
	collection,
	query,
	where,
	onSnapshot,
	getDocs,
	orderBy,
} from 'firebase/firestore';
import '../../../firebase';
import { setBlanksOperation } from '../cityCouncilReducer';

const db = getFirestore();

export const getBlanksCategoriesThunk = createAsyncThunk(
	'cityCancel/blanks/getCategories',
	async (_, { rejectWithValue }) => {
		try {
			const q = query(collection(db, 'blanks_categories'));
			return (await getDocs(q)).docs.map((doc) => doc.data());
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getBlanksThunk = createAsyncThunk(
	'cityCancel/blanks/getBlanks',
	async (_, { getState, dispatch }) => {
		const communityId = getState().cityCouncil.activePlace.communityId;
		const q = query(
			collection(db, 'blanks'),
			where('communityId', '==', communityId),
			where('deleted', '==', false),
			orderBy('index', 'asc')
		);

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			const result = [];
			querySnapshot.forEach((doc) => {
				result.push({
					...doc.data(),
					key: doc.id,
				});
			});
			dispatch(setBlanksOperation(result));
		});

		return unsubscribe;
	}
);
